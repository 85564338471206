@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Bold.eot');
  src: local('Gilroy'), url('./fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Bold.woff2') format('woff2'),
      url('./fonts/Gilroy-Bold.woff') format('woff'),
      url('./fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Medium.eot');
  src: local('Gilroy'), url('./fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Medium.woff2') format('woff2'),
      url('./fonts/Gilroy-Medium.woff') format('woff'),
      url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Light.eot');
  src: local('Gilroy'), url('./fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Light.woff2') format('woff2'),
      url('./fonts/Gilroy-Light.woff') format('woff'),
      url('./fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Regular.eot');
  src: local('Gilroy'), url('./fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Regular.woff2') format('woff2'),
      url('./fonts/Gilroy-Regular.woff') format('woff'),
      url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-RegularItalic.eot');
  src: local('Gilroy'), url('./fonts/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-RegularItalic.woff2') format('woff2'),
      url('./fonts/Gilroy-RegularItalic.woff') format('woff'),
      url('./fonts/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Light.eot');
  src: local('Gilroy'), url('./fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Light.woff2') format('woff2'),
      url('./fonts/Gilroy-Light.woff') format('woff'),
      url('./fonts/Gilroy-Light.ttf') format('truetype'),
      url('./fonts/Gilroy-Light.svg#Gilroy-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url('./fonts/Gilroy-Bold.eot');
  src: local('Gilroy'), url('./fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Bold.woff2') format('woff2'),
      url('./fonts/Gilroy-Bold.woff') format('woff'),
      url('./fonts/Gilroy-Bold.ttf') format('truetype'),
      url('./fonts/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Medium.eot');
  src: url('./fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Medium.woff2') format('woff2'),
      url('./fonts/Gilroy-Medium.woff') format('woff'),
      url('./fonts/Gilroy-Medium.ttf') format('truetype'),
      url('./fonts/Gilroy-Medium.svg#Gilroy-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Regular.eot');
  src: url('./fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-Regular.woff2') format('woff2'),
      url('./fonts/Gilroy-Regular.woff') format('woff'),
      url('./fonts/Gilroy-Regular.ttf') format('truetype'),
      url('./fonts/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-RegularItalic.eot');
  src: url('./fonts/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Gilroy-RegularItalic.woff2') format('woff2'),
      url('./fonts/Gilroy-RegularItalic.woff') format('woff'),
      url('./fonts/Gilroy-RegularItalic.ttf') format('truetype'),
      url('./fonts/Gilroy-RegularItalic.svg#Gilroy-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
