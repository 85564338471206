// natural colors
$white: #fff;
$black: #000;

// general colors
$gray-1: #22144F; // general font
$darkest-purple: #1F164B; // numbers font, footer bg
$purple: #6740E6; // svg triangle
$border-purple: #9E89FF; // border
$lighter-purple: #A98BED; // underline
$seagreen: #48EEC6; // accent
$darker-accent: #009571; // icon admin
$lightest-gray: #E5E5E5; // background

// colors for statistical information
$color-graphics-background: #EDE9FF; // background of graphics
$color-graphics-title: #E3DDFF; // graphics title
$color-graphics-legend: #9AEBE3; // in graphcis
$color-graphics-navigation: #423776; // arrows and text in graphics
$color-graphics-popup: #ECE8FF; // popup backgournd
$color-graphics-popup-border: #9E89FF; // popup border 
$color-graphics-border-dashed: #CEC2FF; // graphics inner borders
$color-graphics-legend-bg: #3E8C94; // graphics legend #2
$color-graphics-legend-bg-border: #48EEC6; // border of legend #2

// other
$input-focus: #00C8D2;
$color-error: #eb3f3f;
