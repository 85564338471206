@import "./Settings.scss";

$load-default: ""; // default grid key - example: class="flex column-$load-default w50-$load-default"

// ----------------------------------------------------------------
// STANDART SETTINGS
// ----------------------------------------------------------------
.container {
	max-width: $container;
	margin: 0 auto;
	padding: 0 $container-padding;
	&.layout {
		max-width: 1280px;
	}
}
.new-row {
	margin-left: -#{$container-padding};
	margin-right: -#{$container-padding};
}

// flex standard
.flex {
	display: flex;
}

// col grid
.col-grid {
	display: flex;
	position: relative;
	width: 100%;
	min-height: 1px;
	max-width: 100%;

	.sidebar {
		flex: 0 0 $sidebar-width;
		max-width: $sidebar-width;
		padding-left: $padding-1;
		padding-right: $padding-1;
	}
	.content {
		flex: 0 0 calc(100% - #{$sidebar-width});
		max-width: calc(100% - #{$sidebar-width});
		padding-left: $padding-1;
		padding-right: $padding-1;
	}
}

.content {
	flex: 0 0 $content-full;
	max-width: $content-full;
}

// ----------------------------------------------------------------
// DEFINE GRID
// ----------------------------------------------------------------

//flex
@mixin flex-grid($selector) {
	.flex {
		&.inline#{$selector} {
			display: inline-flex;
		}
		&.wrap#{$selector} {
			flex-wrap: wrap;
		}
		&.row#{$selector} {
			flex-direction: row;
		}
		&.row-reverse#{$selector} {
			flex-direction: row-reverse;
		}
		&.column#{$selector} {
			flex-direction: column;
		}
		&.column-reverse#{$selector} {
			flex-direction: column-reverse;
		}
		&.ai-center#{$selector} {
			align-items: center;
		}
		&.ai-start#{$selector} {
			align-items: flex-start;
		}
		&.ai-end#{$selector} {
			align-items: flex-end;
		}
		&.ai-stretch#{$selector} {
			align-items: stretch;
		}
		&.jc-center#{$selector} {
			justify-content: center;
		}
		&.jc-sb#{$selector} {
			justify-content: space-between;
		}
		&.jc-start#{$selector} {
			justify-content: flex-start;
		}
		&.jc-end#{$selector} {
			justify-content: flex-end;
		}
		&.jc-stretch#{$selector} {
			justify-content: stretch;
		}
	}
}

//col-grid
@mixin col-grid($selector) {
	.col-grid {
		.side-padding#{$selector} {
			padding-left: $padding-1;
			padding-right: $padding-1;
		}
		.col-1#{$selector} {
			flex: 0 0 100%;
			max-width: 100%;
		}
		.col-2#{$selector} {
			flex: 0 0 50%;
			max-width: 50%;
		}
		.col-3#{$selector} {
			flex: 0 0 calc(100% / 3);
			max-width: calc(100% / 3);

			&.rest {
				flex: 0 0 calc(100% - (100% / 3));
				max-width: calc(100% - (100% / 3));
			}
		}
		.col-4#{$selector} {
			flex: 0 0 25%;
			max-width: 25%;

			&.rest {
				flex: 0 0 75%;
				max-width: 75%;
			}
		}
		.col-5#{$selector} {
			flex: 0 0 20%;
			max-width: 20%;

			&.rest {
				flex: 0 0 80%;
				max-width: 80%;
			}
		}
		.col-6#{$selector} {
			flex: 0 0 calc(100% - (100% / 6));
			max-width: calc(100% - (100% / 6));
		}
	}
}

//gap
@mixin gap($selector) {
	.gap#{$selector} {
		margin-top: $margin-1;
	}
	.gap-small#{$selector} {
		margin-top: $margin-2;
	}

	.gap-large#{$selector} {
		margin-top: $margin-3;
	}
}

//width and max-width
@mixin width($selector) {
	.w100#{$selector} {
		width: 100%;
	}
	.w50#{$selector} {
		width: 50%;
	}
	.w33#{$selector} {
		width: calc(100% / 3);
	}
	.w25#{$selector} {
		width: 25;
	}
	.w20#{$selector} {
		width: 20%;
	}
	.w10#{$selector} {
		width: 10%;
	}
}

@mixin max-width($selector) {
	.max-w100#{$selector} {
		max-width: 100%;
	}
	.max-w50#{$selector} {
		max-width: 50%;
	}
	.max-w33#{$selector} {
		max-width: calc(100% / 3);
	}
	.max-w25#{$selector} {
		max-width: 25;
	}
	.max-w20#{$selector} {
		max-width: 20%;
	}
	.max-w10#{$selector} {
		max-width: 10%;
	}
}

//other
@mixin d-none($selector) {
	.d-none#{$selector} {
		display: none;
	}
}

// this is used to give margin between separated elements on smaller devices
@mixin pad-last($selector) {
	// &.pad-last {
	//   & > *:last-child {
	//     margin-left: $margin-0;
	//   }
	//   &.bigger {
	//     & > *:last-child {
	//       margin-left: $margin-1;
	//     }
	//   }
	// }
}

// ----------------------------------------------------------------
// CUSTOM SETTINGS
// ----------------------------------------------------------------

.relative {
	position: relative;
}

.image,
img {
	&.auto {
		height: auto;

		img {
			height: auto;
		}
	}
	&.full {
		max-width: 100%;

		img {
			max-width: 100%;
		}
	}
}

.ls-none {
	list-style: none;
}

.pe-none {
	pointer-events: none;
}

.td-none,
.td-none a {
	text-decoration: none;
}

// ----------------------------------------------------------------
// DRAW GRID
// ----------------------------------------------------------------

@include d-none($load-default);
@include width($load-default);
@include max-width($load-default);
@include col-grid($load-default);
@include flex-grid($load-default);
@include pad-last($load-default);
// @include gap($load-default);

// @each $selector, $break-point in $gd-breakpoints {
//   @media screen and(max-width: $break-point) {

//     @include d-none(#{$selector});
//     @include width(#{$selector});
//     @include max-width(#{$selector});
//     @include col-grid(#{$selector});
//     @include flex-grid(#{$selector});
//     @include gap(#{$selector});
//   }
// }

// ----------------------------------------------------------------
// END OF DOCUMENT - GRID SETTINGS
// ----------------------------------------------------------------
